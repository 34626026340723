import styled from 'styled-components';
import AppColors from '../../../styles/colors';

export const BodyMiddle = styled.div`
  background: ${AppColors.WhiteF2};
  padding-top: 70px;
  padding-bottom: 50px;
`;
export const HeaderH2 = styled.h2`
  text-align: ${(props) => (props.$textAlign ? 'center' : 'left')};
  position: relative;
  margin-right: 0px;
  margin-bottom: 40px;
  margin-left: 0px;
  padding: 0px 0px 20px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  line-height: 45px;
  color: rgb(31, 178, 90);
  margin-top: 0px !important;
  @media (max-width: 1399px) {
    margin-top: 0px !important;
  }
  @media (max-width: 767px) {
    margin: 0px 0px 30px;
    font-size: 25px;
    line-height: 27px;
  }
`;
export const ParaText = styled.div`
  color: #666;
  white-space: pre-wrap;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    line-height: 22px;
  }
`;
export const ImageStyle = styled.img`
  height: auto;
  width: auto;
  border: 0;
  vertical-align: top;
  margin: 0 0 40px 0;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    max-width: 438px;
  }
  @media (max-width: 575px) {
    max-width: 320px;
  }
`;

export const HeadingH4 = styled.h4`
`;